<template>
	<div class="dept_manage">
		<el-card>
			<el-row class="left mb30">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="部门名称:">
							<el-input v-model="dept_name" placeholder="请输入部门名称" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="addDept">添加</el-button>
					<el-button type="default" size="mini" @click="openTable">展开/折叠</el-button>
					<el-button type="default" size="mini" icon="el-icon-refresh-right">刷新</el-button>
				</el-col>
			</el-row>

			<el-table :data="deptList" ref="deptRef" style="width: 100%; margin-bottom: 20px" height="300" row-key="dept_id" border :default-expand-all="isOpen" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
				<el-table-column label="部门名称" width="150">
					<template slot-scope="scope">
						{{scope.row.dept_name}}
					</template>
				</el-table-column>
				<el-table-column label="部门编码" prop="dept_bh" width="130"></el-table-column>
				<el-table-column label="部门全称" prop="dept_qm" width="150"></el-table-column>
				<el-table-column label="部门备注" prop="dept_bz" width="160"></el-table-column>
				<el-table-column label="排序" prop="dept_sort"></el-table-column>
				<el-table-column label="创建时间" prop="add_time" width="180"></el-table-column>
				<el-table-column label="更新时间" prop="update_time" width="180"></el-table-column>
				<el-table-column label="操作" width="250" fixed="right">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" @click="addDept(scope.row)">添加</el-button>
							<el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
							<el-button type="danger" size="mini" @click="delDept(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<!-- 对话框 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="700px" @close="cancel">
			<el-form ref="deptFormRef" :rules="rules" :model="deptForm" label-width="100px" class="left">
				<el-row>
					<el-col :span="24">
						<el-form-item label="上级部门">
							<treeselect v-model="deptForm.parent_id" :options="deptList" :normalizer="normalizer" :show-count="true" placeholder="请选择上级部门" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="部门名称" prop="dept_name">
							<el-input v-model="deptForm.dept_name" placeholder="请输入部门名称" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="部门编码" prop="dept_bh">
							<el-input v-model="deptForm.dept_bh" placeholder="请输入部门编码" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="部门全称" prop="dept_qm">
							<el-input v-model="deptForm.dept_qm" placeholder="请输入部门全称" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="显示排序" prop="dept_sort">
							<el-input-number v-model="deptForm.dept_sort" controls-position="right" :min="1" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="备注" prop="dept_bz">
							<el-input type="textarea" :rows="4" v-model="deptForm.dept_bz"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="right">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	export default {
		components: {
			Treeselect
		},
		data() {
			return {
				dept_name: "",
				isOpen: true,
				deptList: [],
				dialogVisible: false,
				title: '',
				treeProps: {
					children: "children",
					label: "label",
				},
				deptForm: {
					parent_id: 0,
					dept_name: '',
					dept_bh: '',
					dept_qm: '',
					dept_bz: '',
					dept_sort: 1,
				},
				rules: {
					dept_name: [{
						required: true,
						message: "部门名称不能为空",
						trigger: "blur"
					}],
					dept_sort: [{
						required: true,
						message: "部门顺序不能为空",
						trigger: "blur"
					}],
				}
			};
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getDeptList();
			}
		},
		methods: {
			getDeptList() {
				var url = 'dept/dept_list';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.deptList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//查询
			search() {
				this.getDeptList();
			},
			// 重置
			reset() {
				this.dept_name = "";
				this.getDeptList();
			},
			//折叠
			openTable() {
				this.isOpen = !this.isOpen;
				this.fold(this.deptList, this.isOpen);
			},
			fold(arr, isOpen) {
				arr.forEach((i) => {
					this.$refs.deptRef.toggleRowExpansion(i, isOpen);
					if(i.children) {
						this.fold(i.children, isOpen);
					}
				});
			},
			//添加部门
			addDept(row) {
				if(row != null && row.dept_id) {
					this.deptForm.parent_id = row.dept_id;
				} else {
					this.deptForm.parent_id = undefined;
				}
				this.title = '添加部门'
				this.dialogVisible = true
			},
			//转换部门数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.dept_id,
					label: node.dept_name,
					children: node.children,
				};
			},
			submitForm() {
				this.$refs.deptFormRef.validate(value => {
					if(!value) return
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'dept/addOrUpdate';
						let params = that.deptForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.dialogVisible = false;
								that.$refs.deptFormRef.resetFields();
								that.$message.success('操作成功');
								that.getDeptList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				})
			},
			cancel() {
				this.dialogVisible = false;
				this.reset();
			},
			reset() {
				this.deptForm = {
					parent_id: 0,
					dept_name: '',
					dept_bh: '',
					dept_qm: '',
					dept_bz: '',
					dept_sort: 1,
				}
			},
			update(item) {
				this.title = '修改部门';
				this.deptForm = {
					dept_id: item.dept_id,
					parent_id: item.parent_id,
					dept_name: item.dept_name,
					dept_bh: item.dept_bh,
					dept_qm: item.dept_qm,
					dept_sort: item.dept_sort,
					dept_bz: item.dept_bz
				}
				this.dialogVisible = true;
			},
			delDept(item) {
				this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'dept/deleteDept';
						let params = {
							dept_id: item.dept_id
						};
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success("操作成功");
								that.getDeptList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.dept_manage {
		.left {
			text-align: left;
		}
		.right {
			text-align: right;
		}
		.mb30 {
			margin-bottom: 30px;
		}
	}
</style>